/**
 * @flow
 * @relayHash f16fd9e2d22fe0b09b2ed5171b29f0a1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_Gantt_QueryVariables = {|
  companyId: string,
  strategyId: string,
|};
export type routes1_Gantt_QueryResponse = {|
  +strategy: {|
    +id: string,
    +name: string,
    +description: string,
    +plannedStart: ?any,
    +plannedEnd: ?any,
    +status: Status,
    +messages: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +text: string,
      +from: {|
        +id: string,
        +email: string,
        +name: string,
      |},
    |}>,
    +bigGoals: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +plannedStart: ?any,
      +plannedEnd: ?any,
      +index: number,
      +description: string,
      +status: Status,
      +stepGoals: $ReadOnlyArray<{|
        +name: string,
        +plannedStart: ?any,
        +plannedEnd: ?any,
        +description: string,
        +id: string,
        +responsible: ?{|
          +user: {|
            +id: string,
            +name: string,
          |}
        |},
        +index: number,
        +status: Status,
        +actionPlans: $ReadOnlyArray<{|
          +name: string,
          +description: string,
          +plannedStart: ?any,
          +plannedEnd: ?any,
          +id: string,
          +index: number,
          +status: Status,
          +done: number,
          +responsible: ?{|
            +user: {|
              +id: string,
              +name: string,
            |}
          |},
        |}>,
      |}>,
    |}>,
  |},
  +company: {|
    +id: string,
    +strategies: $ReadOnlyArray<{|
      +id: string,
      +status: Status,
      +name: string,
    |}>,
  |},
  +meProfile: ?{|
    +id: string,
    +name: string,
    +isSuperadmin: ?boolean,
    +activeCompanyId: ?string,
    +activePathId: ?string,
    +email: string,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
  |},
|};
*/


/*
query routes1_Gantt_Query(
  $companyId: ID!
  $strategyId: ID!
) {
  strategy(companyId: $companyId, strategyId: $strategyId) {
    id
    name
    description
    plannedStart
    plannedEnd
    status
    messages {
      id
      createdAt
      text
      from {
        id
        email
        name
      }
    }
    bigGoals {
      id
      name
      plannedStart
      plannedEnd
      index
      description
      status
      stepGoals {
        name
        plannedStart
        plannedEnd
        description
        id
        responsible {
          user {
            id
            name
          }
          id
        }
        index
        status
        actionPlans {
          name
          description
          plannedStart
          plannedEnd
          id
          index
          status
          done
          responsible {
            user {
              id
              name
            }
            id
          }
        }
      }
    }
  }
  company(companyId: $companyId) {
    id
    strategies {
      id
      status
      name
    }
  }
  meProfile: me {
    id
    name
    isSuperadmin
    email
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "strategyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId",
  "type": "ID!"
},
v2 = [
  v1,
  {
    "kind": "Variable",
    "name": "strategyId",
    "variableName": "strategyId",
    "type": "ID!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedStart",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedEnd",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "messages",
  "storageKey": null,
  "args": null,
  "concreteType": "Message",
  "plural": true,
  "selections": [
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "from",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v3,
        v9,
        v4
      ]
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v3,
    v4
  ]
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v12
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "done",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "company",
  "storageKey": null,
  "args": [
    v1
  ],
  "concreteType": "Company",
  "plural": false,
  "selections": [
    v3,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "strategies",
      "storageKey": null,
      "args": null,
      "concreteType": "Strategy",
      "plural": true,
      "selections": [
        v3,
        v8,
        v4
      ]
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperadmin",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "employment",
  "storageKey": null,
  "args": null,
  "concreteType": "Employment",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeRole",
      "args": null,
      "storageKey": null
    }
  ]
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v12,
    v3
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_Gantt_Query",
  "id": null,
  "text": "query routes1_Gantt_Query(\n  $companyId: ID!\n  $strategyId: ID!\n) {\n  strategy(companyId: $companyId, strategyId: $strategyId) {\n    id\n    name\n    description\n    plannedStart\n    plannedEnd\n    status\n    messages {\n      id\n      createdAt\n      text\n      from {\n        id\n        email\n        name\n      }\n    }\n    bigGoals {\n      id\n      name\n      plannedStart\n      plannedEnd\n      index\n      description\n      status\n      stepGoals {\n        name\n        plannedStart\n        plannedEnd\n        description\n        id\n        responsible {\n          user {\n            id\n            name\n          }\n          id\n        }\n        index\n        status\n        actionPlans {\n          name\n          description\n          plannedStart\n          plannedEnd\n          id\n          index\n          status\n          done\n          responsible {\n            user {\n              id\n              name\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n  company(companyId: $companyId) {\n    id\n    strategies {\n      id\n      status\n      name\n    }\n  }\n  meProfile: me {\n    id\n    name\n    isSuperadmin\n    email\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_Gantt_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "strategy",
        "storageKey": null,
        "args": v2,
        "concreteType": "Strategy",
        "plural": false,
        "selections": [
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v10,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bigGoals",
            "storageKey": null,
            "args": null,
            "concreteType": "BigGoal",
            "plural": true,
            "selections": [
              v3,
              v4,
              v6,
              v7,
              v11,
              v5,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "stepGoals",
                "storageKey": null,
                "args": null,
                "concreteType": "StepGoal",
                "plural": true,
                "selections": [
                  v4,
                  v6,
                  v7,
                  v5,
                  v3,
                  v13,
                  v11,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actionPlans",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionPlan",
                    "plural": true,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      v3,
                      v11,
                      v8,
                      v14,
                      v13
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      v15,
      {
        "kind": "LinkedField",
        "alias": "meProfile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3,
          v4,
          v16,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeCompanyId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activePathId",
            "args": null,
            "storageKey": null
          },
          v9,
          v17
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_Gantt_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "strategy",
        "storageKey": null,
        "args": v2,
        "concreteType": "Strategy",
        "plural": false,
        "selections": [
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v10,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bigGoals",
            "storageKey": null,
            "args": null,
            "concreteType": "BigGoal",
            "plural": true,
            "selections": [
              v3,
              v4,
              v6,
              v7,
              v11,
              v5,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "stepGoals",
                "storageKey": null,
                "args": null,
                "concreteType": "StepGoal",
                "plural": true,
                "selections": [
                  v4,
                  v6,
                  v7,
                  v5,
                  v3,
                  v18,
                  v11,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actionPlans",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionPlan",
                    "plural": true,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      v3,
                      v11,
                      v8,
                      v14,
                      v18
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      v15,
      {
        "kind": "LinkedField",
        "alias": "meProfile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3,
          v4,
          v16,
          v9,
          v17
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '35172d52ef5792b77491b09c211f444a';
module.exports = node;
