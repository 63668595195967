/**
 * @flow
 * @relayHash 61af1bb167cf5137590dc6680f2161c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_ActionPlan_QueryVariables = {|
  companyId: string,
  actionPlanId: string,
|};
export type routes1_ActionPlan_QueryResponse = {|
  +me: ?{|
    +id: string,
    +name: string,
  |},
  +actionPlan: {|
    +canView: ?boolean,
    +canEdit: ?boolean,
    +index: number,
    +name: string,
    +description: string,
    +path: ?string,
    +id: string,
    +plannedStart: ?any,
    +plannedEnd: ?any,
    +status: Status,
    +done: number,
    +file: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +size: ?string,
      +createdAt: any,
      +slug: ?string,
      +url: ?string,
      +description: ?string,
    |}>,
    +messages: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +text: string,
      +from: {|
        +id: string,
        +email: string,
        +name: string,
        +pic: ?{|
          +id: string,
          +slug: ?string,
        |},
      |},
    |}>,
    +responsible: ?{|
      +id: string,
      +user: {|
        +id: string,
        +name: string,
      |},
    |},
    +possibleEmployees: ?$ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +user: {|
        +id: string,
        +name: string,
        +email: string,
      |},
    |}>,
    +possibleStrategyEmployees: ?$ReadOnlyArray<{|
      +strategy: {|
        +id: string
      |},
      +employees: $ReadOnlyArray<{|
        +id: string,
        +role: EmployeeRole,
        +user: {|
          +id: string,
          +name: string,
          +email: string,
        |},
      |}>,
    |}>,
  |},
  +company: {|
    +id: string,
    +fileSize: ?string,
  |},
|};
*/


/*
query routes1_ActionPlan_Query(
  $companyId: ID!
  $actionPlanId: ID!
) {
  me {
    id
    name
  }
  actionPlan(companyId: $companyId, actionPlanId: $actionPlanId) {
    canView
    canEdit
    index
    name
    description
    path
    id
    plannedStart
    plannedEnd
    status
    done
    file {
      id
      name
      size
      createdAt
      slug
      url
      description
    }
    messages {
      id
      createdAt
      text
      from {
        id
        email
        name
        pic {
          id
          slug
        }
      }
    }
    responsible {
      id
      user {
        id
        name
      }
    }
    possibleEmployees {
      id
      role
      user {
        id
        name
        email
      }
    }
    possibleStrategyEmployees {
      strategy {
        id
      }
      employees {
        id
        role
        user {
          id
          name
          email
        }
      }
    }
  }
  company(companyId: $companyId) {
    id
    fileSize
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "actionPlanId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  v1,
  v2
],
v4 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId",
  "type": "ID!"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v9 = [
  v1,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "role",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      v1,
      v2,
      v8
    ]
  }
],
v10 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": v3
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "actionPlan",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "actionPlanId",
        "variableName": "actionPlanId",
        "type": "ID!"
      },
      v4
    ],
    "concreteType": "ActionPlan",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "plannedEnd",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "canView",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "index",
        "args": null,
        "storageKey": null
      },
      v2,
      v5,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "path",
        "args": null,
        "storageKey": null
      },
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "plannedStart",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "canEdit",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "done",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "file",
        "storageKey": null,
        "args": null,
        "concreteType": "File",
        "plural": true,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "size",
            "args": null,
            "storageKey": null
          },
          v6,
          v7,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          },
          v5
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "messages",
        "storageKey": null,
        "args": null,
        "concreteType": "Message",
        "plural": true,
        "selections": [
          v1,
          v6,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "text",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "from",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v1,
              v8,
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pic",
                "storageKey": null,
                "args": null,
                "concreteType": "File",
                "plural": false,
                "selections": [
                  v1,
                  v7
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "responsible",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": v3
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "possibleEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": true,
        "selections": v9
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "possibleStrategyEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "StrategyEmployees",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "strategy",
            "storageKey": null,
            "args": null,
            "concreteType": "Strategy",
            "plural": false,
            "selections": [
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "employees",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": v9
          }
        ]
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "company",
    "storageKey": null,
    "args": [
      v4
    ],
    "concreteType": "Company",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "fileSize",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_ActionPlan_Query",
  "id": null,
  "text": "query routes1_ActionPlan_Query(\n  $companyId: ID!\n  $actionPlanId: ID!\n) {\n  me {\n    id\n    name\n  }\n  actionPlan(companyId: $companyId, actionPlanId: $actionPlanId) {\n    canView\n    canEdit\n    index\n    name\n    description\n    path\n    id\n    plannedStart\n    plannedEnd\n    status\n    done\n    file {\n      id\n      name\n      size\n      createdAt\n      slug\n      url\n      description\n    }\n    messages {\n      id\n      createdAt\n      text\n      from {\n        id\n        email\n        name\n        pic {\n          id\n          slug\n        }\n      }\n    }\n    responsible {\n      id\n      user {\n        id\n        name\n      }\n    }\n    possibleEmployees {\n      id\n      role\n      user {\n        id\n        name\n        email\n      }\n    }\n    possibleStrategyEmployees {\n      strategy {\n        id\n      }\n      employees {\n        id\n        role\n        user {\n          id\n          name\n          email\n        }\n      }\n    }\n  }\n  company(companyId: $companyId) {\n    id\n    fileSize\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_ActionPlan_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v10
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_ActionPlan_Query",
    "argumentDefinitions": v0,
    "selections": v10
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8f9ff496e6851d182c72a0cda6deb96e';
module.exports = node;
