/**
 * @flow
 * @relayHash 5904c01170502d03a55533c78fa65da2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_StepGoal_QueryVariables = {|
  companyId: string,
  stepGoalId: string,
|};
export type routes1_StepGoal_QueryResponse = {|
  +me: ?{|
    +id: string,
    +name: string,
  |},
  +stepGoal: {|
    +name: string,
    +index: number,
    +canView: ?boolean,
    +canEdit: ?boolean,
    +path: ?string,
    +description: string,
    +id: string,
    +plannedStart: ?any,
    +plannedEnd: ?any,
    +responsible: ?{|
      +id: string,
      +user: {|
        +id: string,
        +name: string,
      |},
    |},
    +possibleStrategyEmployees: ?$ReadOnlyArray<{|
      +strategy: {|
        +id: string
      |},
      +employees: $ReadOnlyArray<{|
        +id: string,
        +role: EmployeeRole,
        +user: {|
          +id: string,
          +name: string,
          +email: string,
        |},
      |}>,
    |}>,
    +possibleEmployees: ?$ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +user: {|
        +id: string,
        +name: string,
        +email: string,
      |},
    |}>,
    +status: Status,
    +messages: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +text: string,
      +from: {|
        +id: string,
        +email: string,
        +name: string,
      |},
    |}>,
    +actionPlans: $ReadOnlyArray<{|
      +canView: ?boolean,
      +canEdit: ?boolean,
      +name: string,
      +index: number,
      +plannedStart: ?any,
      +plannedEnd: ?any,
      +responsible: ?{|
        +user: {|
          +name: string,
          +id: string,
        |}
      |},
      +description: string,
      +id: string,
      +status: Status,
      +done: number,
    |}>,
  |},
|};
*/


/*
query routes1_StepGoal_Query(
  $companyId: ID!
  $stepGoalId: ID!
) {
  me {
    id
    name
  }
  stepGoal(companyId: $companyId, stepGoalId: $stepGoalId) {
    name
    index
    canView
    canEdit
    path
    description
    id
    plannedStart
    plannedEnd
    responsible {
      id
      user {
        id
        name
      }
    }
    possibleStrategyEmployees {
      strategy {
        id
      }
      employees {
        id
        role
        user {
          id
          name
          email
        }
      }
    }
    possibleEmployees {
      id
      role
      user {
        id
        name
        email
      }
    }
    status
    messages {
      id
      createdAt
      text
      from {
        id
        email
        name
      }
    }
    actionPlans {
      canView
      canEdit
      name
      index
      plannedStart
      plannedEnd
      responsible {
        user {
          name
          id
        }
        id
      }
      description
      id
      status
      done
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "stepGoalId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  v1,
  v2
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": v3
},
v5 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  },
  {
    "kind": "Variable",
    "name": "stepGoalId",
    "variableName": "stepGoalId",
    "type": "ID!"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedStart",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canView",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canEdit",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedEnd",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v1,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": v3
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v15 = [
  v1,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "role",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      v1,
      v2,
      v14
    ]
  }
],
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "possibleStrategyEmployees",
  "storageKey": null,
  "args": null,
  "concreteType": "StrategyEmployees",
  "plural": true,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "strategy",
      "storageKey": null,
      "args": null,
      "concreteType": "Strategy",
      "plural": false,
      "selections": [
        v1
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "employees",
      "storageKey": null,
      "args": null,
      "concreteType": "Employee",
      "plural": true,
      "selections": v15
    }
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "possibleEmployees",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": true,
  "selections": v15
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "messages",
  "storageKey": null,
  "args": null,
  "concreteType": "Message",
  "plural": true,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "from",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v1,
        v14,
        v2
      ]
    }
  ]
},
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v2,
    v1
  ]
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "done",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_StepGoal_Query",
  "id": null,
  "text": "query routes1_StepGoal_Query(\n  $companyId: ID!\n  $stepGoalId: ID!\n) {\n  me {\n    id\n    name\n  }\n  stepGoal(companyId: $companyId, stepGoalId: $stepGoalId) {\n    name\n    index\n    canView\n    canEdit\n    path\n    description\n    id\n    plannedStart\n    plannedEnd\n    responsible {\n      id\n      user {\n        id\n        name\n      }\n    }\n    possibleStrategyEmployees {\n      strategy {\n        id\n      }\n      employees {\n        id\n        role\n        user {\n          id\n          name\n          email\n        }\n      }\n    }\n    possibleEmployees {\n      id\n      role\n      user {\n        id\n        name\n        email\n      }\n    }\n    status\n    messages {\n      id\n      createdAt\n      text\n      from {\n        id\n        email\n        name\n      }\n    }\n    actionPlans {\n      canView\n      canEdit\n      name\n      index\n      plannedStart\n      plannedEnd\n      responsible {\n        user {\n          name\n          id\n        }\n        id\n      }\n      description\n      id\n      status\n      done\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_StepGoal_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "stepGoal",
        "storageKey": null,
        "args": v5,
        "concreteType": "StepGoal",
        "plural": false,
        "selections": [
          v6,
          v2,
          v7,
          v8,
          v9,
          v10,
          v1,
          v11,
          v12,
          v13,
          v16,
          v17,
          v18,
          v19,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actionPlans",
            "storageKey": null,
            "args": null,
            "concreteType": "ActionPlan",
            "plural": true,
            "selections": [
              v12,
              v7,
              v2,
              v11,
              v6,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "responsible",
                "storageKey": null,
                "args": null,
                "concreteType": "Employee",
                "plural": false,
                "selections": [
                  v20
                ]
              },
              v10,
              v1,
              v18,
              v21
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_StepGoal_Query",
    "argumentDefinitions": v0,
    "selections": [
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "stepGoal",
        "storageKey": null,
        "args": v5,
        "concreteType": "StepGoal",
        "plural": false,
        "selections": [
          v6,
          v2,
          v7,
          v8,
          v9,
          v10,
          v1,
          v11,
          v12,
          v13,
          v16,
          v17,
          v18,
          v19,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actionPlans",
            "storageKey": null,
            "args": null,
            "concreteType": "ActionPlan",
            "plural": true,
            "selections": [
              v12,
              v7,
              v2,
              v11,
              v6,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "responsible",
                "storageKey": null,
                "args": null,
                "concreteType": "Employee",
                "plural": false,
                "selections": [
                  v20,
                  v1
                ]
              },
              v10,
              v1,
              v18,
              v21
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '923518d3b6029cf32891047a128ec1b1';
module.exports = node;
