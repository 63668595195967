/**
 * @flow
 * @relayHash d0294968436d0ddc66c8784e8e92fd00
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type routes1_Employee_QueryVariables = {|
  companyId: string
|};
export type routes1_Employee_QueryResponse = {|
  +company: {|
    +id: string,
    +name: string,
    +strategies: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +employees: $ReadOnlyArray<{|
        +id: string
      |}>,
    |}>,
    +invites: $ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +token: ?string,
      +user: {|
        +email: string,
        +name: string,
        +id: string,
      |},
    |}>,
    +employees: $ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +user: {|
        +email: string,
        +name: string,
        +id: string,
      |},
    |}>,
  |},
  +employeeMe: ?{|
    +id: string
  |},
  +profile: ?{|
    +id: string,
    +isSuperadmin: ?boolean,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
  |},
|};
*/


/*
query routes1_Employee_Query(
  $companyId: ID!
) {
  company(companyId: $companyId) {
    id
    name
    strategies {
      id
      name
      employees {
        id
      }
    }
    invites {
      id
      role
      token
      user {
        email
        name
        id
      }
    }
    employees {
      id
      role
      user {
        email
        name
        id
      }
    }
  }
  employeeMe: me {
    id
  }
  profile: me {
    id
    isSuperadmin
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  v1
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "role",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    v2,
    v1
  ]
},
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "company",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId",
        "type": "ID!"
      }
    ],
    "concreteType": "Company",
    "plural": false,
    "selections": [
      v1,
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "strategies",
        "storageKey": null,
        "args": null,
        "concreteType": "Strategy",
        "plural": true,
        "selections": [
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "employees",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": v3
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invites",
        "storageKey": null,
        "args": null,
        "concreteType": "Invite",
        "plural": true,
        "selections": [
          v1,
          v4,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "token",
            "args": null,
            "storageKey": null
          },
          v5
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "employees",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": true,
        "selections": [
          v1,
          v4,
          v5
        ]
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": "employeeMe",
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": v3
  },
  {
    "kind": "LinkedField",
    "alias": "profile",
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isSuperadmin",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "employment",
        "storageKey": null,
        "args": null,
        "concreteType": "Employment",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "employeeId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "employeeName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "employeeRole",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_Employee_Query",
  "id": null,
  "text": "query routes1_Employee_Query(\n  $companyId: ID!\n) {\n  company(companyId: $companyId) {\n    id\n    name\n    strategies {\n      id\n      name\n      employees {\n        id\n      }\n    }\n    invites {\n      id\n      role\n      token\n      user {\n        email\n        name\n        id\n      }\n    }\n    employees {\n      id\n      role\n      user {\n        email\n        name\n        id\n      }\n    }\n  }\n  employeeMe: me {\n    id\n  }\n  profile: me {\n    id\n    isSuperadmin\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_Employee_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v6
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_Employee_Query",
    "argumentDefinitions": v0,
    "selections": v6
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c0abd0343d5b178909b2ee913df816d';
module.exports = node;
