import makeRouteConfig from 'found/lib/makeRouteConfig';
import Route from 'found/lib/Route';
import React from 'react';
import environment from './environment'
import {
  createFragmentContainer,
  commitLocalUpdate,
  graphql,
} from 'react-relay';
import Loadable from 'react-loadable';

const Test1 = Loadable({
  loader: () => import('./components/Test/Test1'),
  loading() {
    return <div></div>
  }
});

const Dashboard = Loadable({
  loader: () => import('./components/Dashboard'),
  loading() {
    return <div></div>
  }
})

const CompanySide = Loadable({
  loader: () => import('./components/side/ActiveCompany'),
  loading() {
    return <div></div>
  }
})

const Header = Loadable({
  loader: () => import('./components/Header'),
  loading() {
    return <div></div>
  }
})

const Home = Loadable({
  loader: () => import('./components/Home'),
  loading() {
    return <div></div>
  }
})

const Company = Loadable({
  loader: () => import('./components/Company'),
  loading() {
    return <div></div>
  }
})

const AdminCompanies = Loadable({
  loader: () => import('./components/admin/Companies'),
  loading() {
    return <div></div>
  }
})

const CreateCompany = Loadable({
  loader: () => import('./components/admin/CreateCompany'),
  loading() {
    return <div></div>
  }
})

const Strategy = Loadable({
  loader: () => import('./components/Strategy').then((x) => x.StrategyPage),
  loading() {
    return <div></div>
  }
})

const Gantt = Loadable({
  loader: () => import('./components/Gantt'),
  loading() {
    return <div></div>
  }
})

const CoachingStrategy = Loadable({
  loader: () => import('./components/CoachingStrategy'),
  loading() {
    return <div></div>
  }
})


const BigGoal = Loadable({
  loader: () => import('./components/BigGoal'),
  loading() {
    return <div></div>
  }
})

const StepGoal = Loadable({
  loader: () => import('./components/StepGoal'),
  loading() {
    return <div></div>
  }
})

const ActionPlan = Loadable({
  loader: () => import('./components/ActionPlan'),
  loading() {
    return <div></div>
  }
})

const CreateStrategyPage = Loadable({
  loader: () => import('./components/CreateStrategy').then((x) => x.CreateStrategyPage),
  loading() {
    return <div></div>
  }
})

const CreateCoachingStrategyPage = Loadable({
  loader: () => import('./components/CreateCoachingStrategy').then((x) => x.CreateCoachingStrategyPage),
  loading() {
    return <div></div>
  }
})

const Employee = Loadable({
  loader: () => import('./components/Employee'),
  loading() {
    return <div></div>
  }
})

const CreateInvite = Loadable({
  loader: () => import('./components/CreateInvite'),
  loading() {
    return <div></div>
  }
})

const Profile = Loadable({
  loader: () => import('./components/Profile'),
  loading() {
    return <div></div>
  }
})

const Login = Loadable({
  loader: () => import('./components/Login'),
  loading() {
    return <div></div>
  }
})

const ForgotPassword = Loadable({
  loader: () => import('./components/ForgotPassword'),
  loading() {
    return <div></div>
  }
})

const ClaimInvite = Loadable({
  loader: () => import('./components/ClaimInvite'),
  loading() {
    return <div></div>
  }
})

const ResetLogin = Loadable({
  loader: () => import('./components/ResetLogin'),
  loading() {
    return <div></div>
  }
})

class Test5 extends React.Component {
  render(args) {
    return <div>Sider</div>
  }
}

const CompanyArchive = Loadable({
  loader: () => import('./components/CompanyArchive'),
  loading() {
    return <div></div>
  }
})

class Test66 extends React.Component {
  render(args) {
    return <div>Test 66</div>
  }
}

class ActiveCompany1 extends React.Component {
  render() {
    return <div>ActiveCompany {this.props.children}</div>
  }
}

class Other extends React.Component {
  render() {
    return <div>Other</div>
  }
}

class Blank extends React.Component {
  render() {
    return <span/>
  }
}

const fileUrl = (slug) => {
  return FILE_SERVER + `/file/${slug}`
}

const localCommit = (f) => {
  commitLocalUpdate(environment, (proxy) => {
    f(proxy)
  })
}

class Wrapper extends React.Component {
  constructor(props) {
    super(props)
    document.getElementById('indicatorArea').style.display = "none"
  }
  render(args) {
    const Component = this.props.routes[1].Component
    return this.props.children
  }
}

const companyHeaderQuery = graphql`
query routes1_CompanyHeader_Query($companyId: ID!) {
  activeCompany:company(companyId: $companyId) {
  ...ActiveCompany_activeCompany @relay(mask: false)
 }
 profile1:me {
  ...Profile_profile @relay(mask: false)
 }
}
`

let helper = {}
//import Test1 from './components/Test/Test1'

export default makeRouteConfig(
  (
    <Route path="/"
           environment={environment}
           helper={helper}
           localCommit={localCommit}
           fileUrl={fileUrl}
           query={graphql`
query routes1_Wrapper_Query {
 me1 {
   ...Wrapper_me1
 }
}
           `}
           Component={Wrapper}

           >
      <Route
          query={graphql`
query routes1_Home_Query {
 me {
  ...Home_me @relay(mask: false)
 }
}`
                         }
            Component={Home}
          path="home" />
      <Route path="login" Component={Login} />
      <Route path="forgot" Component={ForgotPassword} />
      <Route path="/invite/:inviteId/:companyId"
             query={graphql`
      query routes1_ClaimInvite_Query($companyId: ID!, $inviteId: ID!) {

        viewInvite(id: $inviteId, companyId: $companyId) {
          ...ClaimInvite_viewInvite
        }
      }
             `}
             Component={ClaimInvite} />
      <Route path="/reset-password/:token"
             headerType="resetLogin"
             query={graphql`
query routes1_ResetLogin_Query($token: String!) {
 person:claimReset(token: $token) {
  ...ResetLogin_person @relay(mask: false)
 }
}
             `}
             Component={ResetLogin} />,
      <Route path="other"
             Component={Other}

             />
      // This would be the Dashboard
      // Would grab values for profile
      <Route
         query={graphql`
query routes1_Dashboard_Query {
 profile:me {
  ...Profile_profile @relay(mask: false)
 }
}
         `}
         Component={Dashboard} >
        (<Route
            render={()=> <span/>}
            path="/admin/companies">
          {{side: (<Route
                        Component={Blank}
                        path="(.*)?"/>),
            header: (<Route
                     headerType="adminCompanies"
                     Component={Header}
                     path="(.*)?"/>),
            main: (<Route
                   query={graphql`
query routes1_AdminCompanies_Query {
 profile:me {
  ...Profile_profile @relay(mask: false)
 }
 me {
  ...Companies_me @relay(mask: false)
 }
}`
                         }
                   Component={AdminCompanies}
                   path="(.*?)?"/>)}}
         </Route>
        )
    (<Route
            render={()=> <span/>}
            path="/admin/create">
          {{side: (<Route
                        Component={Blank}
                        path="(.*)?"/>),
            header: (<Route
                     headerType="createCompany"
                     Component={Header}
                     path="(.*)?"/>),
            main: (<Route
           query={graphql`
query routes1_CreateCompany_Query {
 profile:me {
  ...Profile_profile @relay(mask: false)
 }
 me {
  ...CreateCompany_me @relay(mask: false)
 }
}`
           }
                   Component={CreateCompany}
                   path="(.*?)?"/>)}}
         </Route>
        )
    (
      <Route
         render={()=> <span/>}
         path="/profile" >
        {{side: (<Route
                      prepareVariables={(params) => {
                        // Can insert the companyId from localStorage
                        params['companyId'] = localStorage.getItem('activeCompanyId')
                        return params
                      }}
                 query={companyHeaderQuery}
                 Component={CompanySide}
                 path="(.*)?"/>),
          header: (<Route
                   headerType="profile"
                   Component={Header}
                   path="(.*)?"/>),
          main: (<Route
                 query={graphql`
query routes1_Profile_Query {
 profile:me {
  ...Profile_profile @relay(mask: false)
 }
 companies:me {
  ...ActiveCompany_companies @relay(mask: false)
 }

}`
                       }
                 Component={Profile}
                 path="(.*?)?"/>)}}
      </Route>
    )
    (
      <Route
         render={()=> <span/>}
        path="/admin/profile" >
        {{side: (<Route
                      render={() => <span/>}
                 path="(.*)?"/>),
          header: (<Route
                   headerType="profile"
                   Component={Header}
                   path="(.*)?"/>),
          main: (<Route
                 query={graphql`
query routes1_AdminProfile_Query {
 profile:me {
  ...Profile_profile @relay(mask: false)
 }
 companies:me {
  ...ActiveCompany_companies @relay(mask: false)
 }

}`
                       }
                 Component={Profile}
                 path="(.*?)?"/>)}}
      </Route>
    )



      <Route
    path="company/:companyId"
    render={()=> <span/>}
      >
      {{side: (
        <Route path="(.*)?"
               prepareVariables={(params) => {
                 // Can insert the companyId from localStorage
                 return params
          }}
          query={companyHeaderQuery}
          Component={CompanySide} />
      ),
        header: (
          <Route
             query={companyHeaderQuery}
             path="(.*)?"
             Component={Header} />
        ),
        main: [
            <Route
          query={graphql`
query routes1_Strategy_Query($companyId: ID!, $strategyId: ID!) {
 profile:me {
  ...Profile_profile @relay(mask: false)
 }
 strategy(companyId: $companyId, strategyId: $strategyId) {
  ...Strategy_strategy @relay(mask: false)
 }

}`
                }
          path="/strategy/:strategyId"

              Component={Strategy} />,
          <Route
            query={graphql`
query routes1_CoachingStrategy_Query($companyId: ID!, $strategyId: ID!) {
 me {
  ...CoachingStrategy_me @relay(mask: false)
 }
 strategy(companyId: $companyId, strategyId: $strategyId) {
  ...CoachingStrategy_strategy @relay(mask: false)
 }

}`
                  }
            path="/coachingstrategy/:strategyId"

            Component={CoachingStrategy} />,
          <Route
             query={graphql`
query routes1_BigGoal_Query($companyId: ID!, $bigGoalId: ID!) {
 me {
  ...BigGoal_me @relay(mask: false)
 }
 bigGoal(companyId: $companyId, bigGoalId: $bigGoalId) {
  ...BigGoal_bigGoal @relay(mask: false)
 }

}`
             }
             path="/bigGoal/:bigGoalId"

             Component={BigGoal} />,
          <Route
             query={graphql`
query routes1_StepGoal_Query($companyId: ID!, $stepGoalId: ID!) {
 me {
  ...BigGoal_me @relay(mask: false)
 }
 stepGoal(companyId: $companyId, stepGoalId: $stepGoalId) {
  ...StepGoal_stepGoal @relay(mask: false)
 }

}`
             }
             path="/stepGoal/:stepGoalId"

             Component={StepGoal} />,
          <Route
             query={graphql`
query routes1_ActionPlan_Query($companyId: ID!, $actionPlanId: ID!) {
 me {
  ...BigGoal_me @relay(mask: false)
 }
 actionPlan(companyId: $companyId, actionPlanId: $actionPlanId) {
  ...ActionPlan_actionPlan @relay(mask: false)
 }
 company(companyId: $companyId) {
  ...ActionPlan_company @relay(mask: false)
 }

}`
             }
             path="/actionPlan/:actionPlanId"

             Component={ActionPlan} />,
          <Route
             query={graphql`
query routes1_CompanyArchive_Query($companyId: ID!) {
 company(companyId: $companyId) {
  ...CompanyArchive_company
 }

}`
             }
             path="/archive"
             Component={CompanyArchive} />,
          <Route Component={CreateStrategyPage}
                 headerType="createStrategyPage"
                 query={graphql`
query routes1_CreateStrategy_Query($companyId: ID!) {
 me {
  ...CreateStrategy_me @relay(mask: false)
 }
 company(companyId: $companyId) {
  ...CreateStrategy_company @relay(mask: false)
 }
}`
                 }
                 loginRequired
                 path="/createStrategy"
                 exact/>,
          <Route Component={CreateCoachingStrategyPage}
                 headerType="createCoachingStrategyPage"
                 query={graphql`
query routes1_CreateCoachingStrategy_Query($companyId: ID!) {
 company(companyId: $companyId) {
  ...CreateStrategy_company @relay(mask: false)
 }
 me {
  ...CreateCoachingStrategy_me @relay(mask: false)
 }
}`
                       }
                 loginRequired
                 path="/createCoachingStrategy"
                 exact/>,
          <Route Component={CreateInvite}
                 headerType="createInvite"
                 path="/invite"
                 exact/>,

          <Route Component={Employee}
                 headerType="employees"
                 query={graphql`
query routes1_Employee_Query($companyId: ID!) {
 company(companyId: $companyId) {
  ...Employee_company @relay(mask: false)
 }
 employeeMe:me {
  ...Employee_employeeMe @relay(mask: false)
 }
 profile:me {
  ...Employee_profile @relay(mask: false)
 }

}`
                 }
                 loginRequired
                 path="/employee"
                 exact/>,
          <Route
             Component={Gantt}
             headerType="timeline"
             path="/timeline/:strategyId"
             query={graphql`
query routes1_Gantt_Query($companyId: ID!, $strategyId: ID!) {
 strategy(companyId: $companyId, strategyId: $strategyId) {
  ...Gantt_strategy @relay(mask: false)
 }
 company(companyId: $companyId) {
  ...Gantt_company @relay(mask: false)
 }
 meProfile:me {
  ...Gantt_meProfile @relay(mask: false)
 }
             }`} />,
          <Route
             query={graphql`
query routes1_CompanyDetails_Query($companyId: ID!) {
 profile:me {
  ...Profile_profile @relay(mask: false)
 }
 company(companyId: $companyId) {
  ...Company_company @relay(mask: false)
 }

}`
             }
             Component={Company} />,

        ]
       }}
    </Route>
      </Route>

      </Route>
  )

)
