import BrowserProtocol from 'farce/lib/BrowserProtocol';
import queryMiddleware from 'farce/lib/queryMiddleware';
import createFarceRouter from 'found/lib/createFarceRouter';
import createRender from 'found/lib/createRender';
import { Resolver } from 'found-relay';
import React from 'react';
import ReactDOM from 'react-dom';
import environment from 'environment'
import "typeface-open-sans"
import routes from 'routes1';
import PlayLogo from "assets/images/play.png"
import FavIcon from "assets/images/fav.png"
//import schema from './data/schema';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'assets/styles.scss'

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(), //new HashProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,

  render: createRender({}),
});

const mountNode = document.createElement('div');
const indicatorNode = document.createElement('div');
indicatorNode.setAttribute('id', 'indicatorArea')
// indicatorNode.style.width = "10%"
// indicatorNode.style.height = "10%"
indicatorNode.style.position = "fixed"
indicatorNode.style.left = "45%"
indicatorNode.style.top = "40%"
indicatorNode.style.display = "block"
document.body.appendChild(mountNode);
document.body.appendChild(indicatorNode)
const favIcon = document.createElement("link")
favIcon.setAttribute("href", "/images/fav.png")
favIcon.setAttribute("rel", "icon")
favIcon.setAttribute("sizes", "32x32")
favIcon.setAttribute("type", "image/png")
document.head.appendChild(favIcon)

const notLoggedIn = !window.localStorage.getItem('userToken')

if (notLoggedIn && !( ((location.pathname.indexOf('invite') > 0) && (location.pathname.indexOf('company') < 0)) ||
                     location.pathname.indexOf('forgot') > 0 ||
                     location.pathname.indexOf('reset') > 0 ||
                     location.pathname.indexOf('login') > 0)) {
  window.location.replace("/login")
}
const theme = createMuiTheme({
  overrides: {
    Typography: {
      root: {
        color: '#474c55',  
      }
      
    },
    Card: {
      root: {
        color: '#474c55',  
      },
      cardRaised: {
        backgroundColor: "red"
      }
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font. 
    fontFamily: [
      'Montserrat'
    ].join(','),
  },
});
if (!notLoggedIn && location.pathname == '/') {
  window.location.replace("/home")
}

ReactDOM.render(<MuiThemeProvider theme={theme}><Router resolver={new Resolver(environment)} /></MuiThemeProvider>, mountNode);

//import index from "material-admin/index"
