/**
 * @flow
 * @relayHash 76b6fadde47a71c08c4d9a2bc64e16fc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_CompanyDetails_QueryVariables = {|
  companyId: string
|};
export type routes1_CompanyDetails_QueryResponse = {|
  +profile: ?{|
    +id: string,
    +name: string,
    +isSuperadmin: ?boolean,
    +activeCompanyId: ?string,
    +activePathId: ?string,
    +email: string,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
    +pic: ?{|
      +id: string,
      +name: string,
      +slug: ?string,
    |},
  |},
  +company: {|
    +id: string,
    +canView: ?boolean,
    +canEdit: ?boolean,
    +name: string,
    +fileSize: ?string,
    +strategies: $ReadOnlyArray<{|
      +id: string,
      +path: ?string,
      +canView: ?boolean,
      +isCoaching: ?boolean,
      +canEdit: ?boolean,
      +name: string,
      +description: string,
      +plannedStart: ?any,
      +plannedEnd: ?any,
      +status: Status,
      +bigGoals: $ReadOnlyArray<{|
        +id: string,
        +canView: ?boolean,
        +canEdit: ?boolean,
        +name: string,
        +plannedStart: ?any,
        +plannedEnd: ?any,
        +index: number,
        +status: Status,
        +description: string,
        +responsible: ?{|
          +user: {|
            +name: string,
            +id: string,
          |}
        |},
        +stepGoals: $ReadOnlyArray<{|
          +name: string,
          +plannedStart: ?any,
          +canEdit: ?boolean,
          +canView: ?boolean,
          +plannedEnd: ?any,
          +description: string,
          +id: string,
          +index: number,
          +status: Status,
          +responsible: ?{|
            +user: {|
              +name: string,
              +id: string,
            |}
          |},
          +actionPlans: $ReadOnlyArray<{|
            +name: string,
            +canEdit: ?boolean,
            +canView: ?boolean,
            +description: string,
            +plannedStart: ?any,
            +plannedEnd: ?any,
            +id: string,
            +index: number,
            +status: Status,
            +done: number,
            +responsible: ?{|
              +user: {|
                +name: string,
                +id: string,
              |}
            |},
          |}>,
        |}>,
      |}>,
    |}>,
  |},
|};
*/


/*
query routes1_CompanyDetails_Query(
  $companyId: ID!
) {
  profile: me {
    id
    name
    isSuperadmin
    email
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
    pic {
      id
      name
      slug
    }
  }
  company(companyId: $companyId) {
    id
    canView
    canEdit
    name
    fileSize
    strategies {
      id
      path
      canView
      isCoaching
      canEdit
      name
      description
      plannedStart
      plannedEnd
      status
      bigGoals {
        id
        canView
        canEdit
        name
        plannedStart
        plannedEnd
        index
        status
        description
        responsible {
          user {
            name
            id
          }
          id
        }
        stepGoals {
          name
          plannedStart
          canEdit
          canView
          plannedEnd
          description
          id
          index
          status
          responsible {
            user {
              name
              id
            }
            id
          }
          actionPlans {
            name
            canEdit
            canView
            description
            plannedStart
            plannedEnd
            id
            index
            status
            done
            responsible {
              user {
                name
                id
              }
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperadmin",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "employment",
  "storageKey": null,
  "args": null,
  "concreteType": "Employment",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeRole",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pic",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canView",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canEdit",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileSize",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isCoaching",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedStart",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedEnd",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v2,
    v1
  ]
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v18
  ]
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "done",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v18,
    v1
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_CompanyDetails_Query",
  "id": null,
  "text": "query routes1_CompanyDetails_Query(\n  $companyId: ID!\n) {\n  profile: me {\n    id\n    name\n    isSuperadmin\n    email\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n    pic {\n      id\n      name\n      slug\n    }\n  }\n  company(companyId: $companyId) {\n    id\n    canView\n    canEdit\n    name\n    fileSize\n    strategies {\n      id\n      path\n      canView\n      isCoaching\n      canEdit\n      name\n      description\n      plannedStart\n      plannedEnd\n      status\n      bigGoals {\n        id\n        canView\n        canEdit\n        name\n        plannedStart\n        plannedEnd\n        index\n        status\n        description\n        responsible {\n          user {\n            name\n            id\n          }\n          id\n        }\n        stepGoals {\n          name\n          plannedStart\n          canEdit\n          canView\n          plannedEnd\n          description\n          id\n          index\n          status\n          responsible {\n            user {\n              name\n              id\n            }\n            id\n          }\n          actionPlans {\n            name\n            canEdit\n            canView\n            description\n            plannedStart\n            plannedEnd\n            id\n            index\n            status\n            done\n            responsible {\n              user {\n                name\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_CompanyDetails_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "profile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeCompanyId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activePathId",
            "args": null,
            "storageKey": null
          },
          v4,
          v5,
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": v7,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v1,
          v8,
          v9,
          v2,
          v10,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "strategies",
            "storageKey": null,
            "args": null,
            "concreteType": "Strategy",
            "plural": true,
            "selections": [
              v2,
              v1,
              v8,
              v11,
              v9,
              v12,
              v13,
              v14,
              v15,
              v16,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bigGoals",
                "storageKey": null,
                "args": null,
                "concreteType": "BigGoal",
                "plural": true,
                "selections": [
                  v15,
                  v1,
                  v9,
                  v2,
                  v14,
                  v8,
                  v17,
                  v16,
                  v13,
                  v19,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "stepGoals",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StepGoal",
                    "plural": true,
                    "selections": [
                      v13,
                      v2,
                      v9,
                      v8,
                      v15,
                      v14,
                      v1,
                      v17,
                      v16,
                      v19,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "actionPlans",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionPlan",
                        "plural": true,
                        "selections": [
                          v15,
                          v2,
                          v8,
                          v13,
                          v14,
                          v9,
                          v1,
                          v17,
                          v16,
                          v20,
                          v19
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_CompanyDetails_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "profile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": v7,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v1,
          v8,
          v9,
          v2,
          v10,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "strategies",
            "storageKey": null,
            "args": null,
            "concreteType": "Strategy",
            "plural": true,
            "selections": [
              v2,
              v1,
              v8,
              v11,
              v9,
              v12,
              v13,
              v14,
              v15,
              v16,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bigGoals",
                "storageKey": null,
                "args": null,
                "concreteType": "BigGoal",
                "plural": true,
                "selections": [
                  v15,
                  v1,
                  v9,
                  v2,
                  v14,
                  v8,
                  v17,
                  v16,
                  v13,
                  v21,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "stepGoals",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StepGoal",
                    "plural": true,
                    "selections": [
                      v13,
                      v2,
                      v9,
                      v8,
                      v15,
                      v14,
                      v1,
                      v17,
                      v16,
                      v21,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "actionPlans",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionPlan",
                        "plural": true,
                        "selections": [
                          v15,
                          v2,
                          v8,
                          v13,
                          v14,
                          v9,
                          v1,
                          v17,
                          v16,
                          v20,
                          v21
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c614bf548dba54745d7bceaf46700912';
module.exports = node;
