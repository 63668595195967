/**
 * @flow
 * @relayHash d65f177ee589a61b936b838378f121f3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type routes1_AdminCompanies_QueryVariables = {||};
export type routes1_AdminCompanies_QueryResponse = {|
  +profile: ?{|
    +id: string,
    +name: string,
    +isSuperadmin: ?boolean,
    +activeCompanyId: ?string,
    +activePathId: ?string,
    +email: string,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
    +pic: ?{|
      +id: string,
      +name: string,
      +slug: ?string,
    |},
  |},
  +me: ?{|
    +id: string,
    +isSuperadmin: ?boolean,
    +adminCompanies: ?$ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
  |},
|};
*/


/*
query routes1_AdminCompanies_Query {
  profile: me {
    id
    name
    isSuperadmin
    email
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
    pic {
      id
      name
      slug
    }
  }
  me {
    id
    isSuperadmin
    adminCompanies {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperadmin",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "employment",
  "storageKey": null,
  "args": null,
  "concreteType": "Employment",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeRole",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pic",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v0,
    v2,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "adminCompanies",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": true,
      "selections": [
        v0,
        v1
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_AdminCompanies_Query",
  "id": null,
  "text": "query routes1_AdminCompanies_Query {\n  profile: me {\n    id\n    name\n    isSuperadmin\n    email\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n    pic {\n      id\n      name\n      slug\n    }\n  }\n  me {\n    id\n    isSuperadmin\n    adminCompanies {\n      id\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_AdminCompanies_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "profile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeCompanyId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activePathId",
            "args": null,
            "storageKey": null
          },
          v3,
          v4,
          v5
        ]
      },
      v6
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_AdminCompanies_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "profile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          v5
        ]
      },
      v6
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '370158ce093fa7a754047446aa760490';
module.exports = node;
