import { Environment, RecordSource, Network, Store, commitLocalUpdate } from 'relay-runtime';
import graphqlMask from "graphql-mask"
import remoteSchema from "data/remoteOnly.graphql"
import {createUUID, createState} from "local-util"

let queryIsPending = false
let queryMap = {}

const createQueryMap = (operation, variables) => {
  let resolveF, errorF
  let p = new Promise((resolve, error) => {
    resolveF = resolve
    errorF = error
  })
  
  return {
    promise: p,
    resolve: resolveF,
    error: errorF,
    operation: operation,
    variables,
  }
}

const getQueryVariables = (queries) => {
  let variablesObj = {}
  let variablesArray = []
  
  queries.forEach((q) => {
    q.operation.operation.argumentDefinitions.forEach((a) => {
      if (!variablesObj[a.name]) {
        variablesArray.push(`$${a.name}: ${a.type}`)
        variablesObj[a.name] = q.variables[a.name]      
      }
      
    })    
  })

  return {
    variables: variablesObj,
    str: variablesArray.join(",")
  }  
}

const extractQueryText = (text) => {
  var startRe = new RegExp(/[^]+?\{/)
  const start = text.replace(startRe, '')
  return start.substring(0, start.length -2)
}

const fetchQueryParameters = (queries) => {
  const {variables, str} = getQueryVariables(queries)

  const queryBody = queries.map((q) => {
    return extractQueryText(q.operation.text)
  }).join("\n")

  const queryHeader = (str.length > 3) ? `(${str})` : ''
  
  return {
    operationName: 'QueryBulk',
    operationKind: 'query',
    operationText: `query QueryBulk${queryHeader} {
${queryBody}
}`,
    variables
  }

}

let promiseQueryMap = {
  
}

let localCommiter

let delays = {
  
}

const convertOperationName = (name) => {
  return name.replace("CompanyHeader", "Company").replace("CompanySide", "Company")
}

// if development
// delays['ClaimInviteMutation'] = 2000
// delays['ChangeAttributeMutation'] = 500
// delays['CreateMessageMutation'] = 800
// delays['ChangeUserPasswordMutation'] = 800

const delay = (time, value) => {
  return new Promise((resolve) => {    
    window.setTimeout(() => {      
      resolve(value)
    }, time)    
  })
}

const fetchHelper = async ({operationName, operationKind, operationText,
                      variables, cacheConfig, uploadables}) =>
        {
          console.log("What is getting sent?", {operationName, operationKind,
                      operationText, variables})
          const delayTime = delays[operationName]
          
          let headers = {
            // Add authentication and other headers here
            'content-type': 'application/json'
          }

          const token = localStorage.getItem('userToken')
          
          if (token) {
            headers['Authorization'] = "Bearer " + token
          }
          console.log("Server?", GRAPHQL_SERVER)         
          return fetch(GRAPHQL_SERVER, {
            method: 'POST',
            headers,
            body: JSON.stringify({
              query: graphqlMask(remoteSchema, operationText), // GraphQL text from input
              variables,
            }),
          }).then(response => {

            console.log("Response back from server")
            if (operationKind == "query") {      
              document.getElementById('indicatorArea').style.display = "none"      
            }
            
            const r = response.json()    
            
            if (delayTime) {
              return delay(delayTime, r)
            } else {
              return r
            }
            
          }).then((json, x) => {

            
            if (json && json.data && (json.data.me === null)) {
              window.localStorage.clear()
              window.location.reload()              
            }
            
            const p = promiseQueryMap[operationName]
            
            if (!json.data && json.errors) {
              if (operationName == 'query') {      
                Object.values(queryMap).forEach((q) => {
                  q.error(json.errors)
                })
              }

              queryMap = {}
              
              queryIsPending = false
              return Promise.reject(json.errors);
            }
            
            if (operationKind == 'query') {              
              Object.values(queryMap).forEach((q) => {                
                q.resolve(json)
              })
              queryIsPending = false
              queryMap = {}
            }
            
            return Promise.resolve(json);
          })
          
        }


// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(
  operation,
  variables,
  cacheConfig,
  uploadables,
) {
  
  const operationName = operation.name
  let sendFn
  
  if (operationName == 'routes1_Test1_Query' ) {
    return Promise.resolve({data: {test3: {id: "55", name: "Aardvark"}}})
  }

  if (operationName == 'routes1_Wrapper_Query' ) {
    return Promise.resolve({data: {me1: {id: "555", name: "Aar333dvark"}}})    
  }
  
  if (operation.operationKind == "query") {

    if (!queryIsPending) {
      
      sendFn = () => {        
        fetchHelper(fetchQueryParameters(Object.values(queryMap)))        
      }
      
    }

    let qm = queryMap[operation.name]
    
    if (!qm) {
      qm = createQueryMap(operation, variables)
      queryMap[operation.name] = qm
    }
    
    if (sendFn) {      
      setTimeout(sendFn, 50)
      queryIsPending = true
    }
    
    document.getElementById('indicatorArea').style.display = "block"
    return qm.promise
    
  } else {
    return fetchHelper({operationName: operation.name,
                  operationKind: operation.kind,
                  operationText: operation.text,
                  variables, cacheConfig, uploadables})
  }
  
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);
const store = new Store(new RecordSource())
const environment = new Environment({
  network: Network.create(fetchQuery),
  store,
});

localCommiter = (f) => {
  commitLocalUpdate(Environment, f)
}

commitLocalUpdate(environment, (proxy) => {  
  const notificationRootId = createUUID()
  const stateId = createUUID()  
  let notificationRoot = proxy.create(notificationRootId)
  notificationRoot.setValue(notificationRootId, 'id')
  let state = createState({name: "asddsaf", attr: "attr-va", value: "valuey", proxy})  
  notificationRoot.setLinkedRecords([state], 'notifications')
  
  proxy.getRoot().setLinkedRecord(notificationRoot, 'notificationArea')

  
  
})


export default  environment
