/**
 * @flow
 * @relayHash af5d14c7f662cc76528f38104ab269b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ClaimInvite_viewInvite$ref = any;
export type routes1_ClaimInvite_QueryVariables = {|
  companyId: string,
  inviteId: string,
|};
export type routes1_ClaimInvite_QueryResponse = {|
  +viewInvite: {|
    +$fragmentRefs: ClaimInvite_viewInvite$ref
  |}
|};
*/


/*
query routes1_ClaimInvite_Query(
  $companyId: ID!
  $inviteId: ID!
) {
  viewInvite(id: $inviteId, companyId: $companyId) {
    ...ClaimInvite_viewInvite
    id
  }
}

fragment ClaimInvite_viewInvite on Invite {
  companyName
  id
  user {
    id
    hasPassword
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "inviteId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "inviteId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_ClaimInvite_Query",
  "id": null,
  "text": "query routes1_ClaimInvite_Query(\n  $companyId: ID!\n  $inviteId: ID!\n) {\n  viewInvite(id: $inviteId, companyId: $companyId) {\n    ...ClaimInvite_viewInvite\n    id\n  }\n}\n\nfragment ClaimInvite_viewInvite on Invite {\n  companyName\n  id\n  user {\n    id\n    hasPassword\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_ClaimInvite_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewInvite",
        "storageKey": null,
        "args": v1,
        "concreteType": "Invite",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ClaimInvite_viewInvite",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_ClaimInvite_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewInvite",
        "storageKey": null,
        "args": v1,
        "concreteType": "Invite",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyName",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPassword",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd4aad2cb8d0dae9f37c98a40950f0ff';
module.exports = node;
