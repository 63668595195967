/**
 * @flow
 * @relayHash 03d9127a57798721dde8cb31734dbbcb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_BigGoal_QueryVariables = {|
  companyId: string,
  bigGoalId: string,
|};
export type routes1_BigGoal_QueryResponse = {|
  +me: ?{|
    +id: string,
    +name: string,
  |},
  +bigGoal: {|
    +name: string,
    +index: number,
    +canEdit: ?boolean,
    +canView: ?boolean,
    +path: ?string,
    +description: string,
    +id: string,
    +plannedStart: ?any,
    +plannedEnd: ?any,
    +status: Status,
    +responsible: ?{|
      +id: string,
      +user: {|
        +id: string,
        +name: string,
      |},
    |},
    +possibleEmployees: ?$ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +user: {|
        +id: string,
        +name: string,
        +email: string,
      |},
    |}>,
    +messages: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +text: string,
      +from: {|
        +id: string,
        +email: string,
        +name: string,
      |},
    |}>,
    +stepGoals: $ReadOnlyArray<{|
      +canEdit: ?boolean,
      +canView: ?boolean,
      +name: string,
      +index: number,
      +plannedStart: ?any,
      +plannedEnd: ?any,
      +description: string,
      +id: string,
      +responsible: ?{|
        +user: {|
          +name: string,
          +id: string,
        |}
      |},
      +status: Status,
      +actionPlans: $ReadOnlyArray<{|
        +canEdit: ?boolean,
        +canView: ?boolean,
        +responsible: ?{|
          +user: {|
            +name: string,
            +id: string,
          |}
        |},
        +name: string,
        +plannedStart: ?any,
        +plannedEnd: ?any,
        +status: Status,
        +description: string,
        +id: string,
        +index: number,
        +done: number,
      |}>,
    |}>,
  |},
|};
*/


/*
query routes1_BigGoal_Query(
  $companyId: ID!
  $bigGoalId: ID!
) {
  me {
    id
    name
  }
  bigGoal(companyId: $companyId, bigGoalId: $bigGoalId) {
    name
    index
    canEdit
    canView
    path
    description
    id
    plannedStart
    plannedEnd
    status
    responsible {
      id
      user {
        id
        name
      }
    }
    possibleEmployees {
      id
      role
      user {
        id
        name
        email
      }
    }
    messages {
      id
      createdAt
      text
      from {
        id
        email
        name
      }
    }
    stepGoals {
      canEdit
      canView
      name
      index
      plannedStart
      plannedEnd
      description
      id
      responsible {
        user {
          name
          id
        }
        id
      }
      status
      actionPlans {
        canEdit
        canView
        responsible {
          user {
            name
            id
          }
          id
        }
        name
        plannedStart
        plannedEnd
        status
        description
        id
        index
        done
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bigGoalId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  v1,
  v2
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": v3
},
v5 = [
  {
    "kind": "Variable",
    "name": "bigGoalId",
    "variableName": "bigGoalId",
    "type": "ID!"
  },
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedStart",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canEdit",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canView",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedEnd",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v1,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": v3
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "possibleEmployees",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": true,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "role",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v1,
        v2,
        v15
      ]
    }
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "messages",
  "storageKey": null,
  "args": null,
  "concreteType": "Message",
  "plural": true,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "from",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v1,
        v15,
        v2
      ]
    }
  ]
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v2,
    v1
  ]
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v18
  ]
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "done",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v18,
    v1
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_BigGoal_Query",
  "id": null,
  "text": "query routes1_BigGoal_Query(\n  $companyId: ID!\n  $bigGoalId: ID!\n) {\n  me {\n    id\n    name\n  }\n  bigGoal(companyId: $companyId, bigGoalId: $bigGoalId) {\n    name\n    index\n    canEdit\n    canView\n    path\n    description\n    id\n    plannedStart\n    plannedEnd\n    status\n    responsible {\n      id\n      user {\n        id\n        name\n      }\n    }\n    possibleEmployees {\n      id\n      role\n      user {\n        id\n        name\n        email\n      }\n    }\n    messages {\n      id\n      createdAt\n      text\n      from {\n        id\n        email\n        name\n      }\n    }\n    stepGoals {\n      canEdit\n      canView\n      name\n      index\n      plannedStart\n      plannedEnd\n      description\n      id\n      responsible {\n        user {\n          name\n          id\n        }\n        id\n      }\n      status\n      actionPlans {\n        canEdit\n        canView\n        responsible {\n          user {\n            name\n            id\n          }\n          id\n        }\n        name\n        plannedStart\n        plannedEnd\n        status\n        description\n        id\n        index\n        done\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_BigGoal_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "bigGoal",
        "storageKey": null,
        "args": v5,
        "concreteType": "BigGoal",
        "plural": false,
        "selections": [
          v6,
          v2,
          v7,
          v8,
          v9,
          v10,
          v1,
          v11,
          v12,
          v13,
          v14,
          v16,
          v17,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "stepGoals",
            "storageKey": null,
            "args": null,
            "concreteType": "StepGoal",
            "plural": true,
            "selections": [
              v12,
              v7,
              v2,
              v11,
              v6,
              v8,
              v10,
              v1,
              v19,
              v13,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actionPlans",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionPlan",
                "plural": true,
                "selections": [
                  v12,
                  v7,
                  v19,
                  v2,
                  v6,
                  v8,
                  v13,
                  v10,
                  v1,
                  v11,
                  v20
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_BigGoal_Query",
    "argumentDefinitions": v0,
    "selections": [
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "bigGoal",
        "storageKey": null,
        "args": v5,
        "concreteType": "BigGoal",
        "plural": false,
        "selections": [
          v6,
          v2,
          v7,
          v8,
          v9,
          v10,
          v1,
          v11,
          v12,
          v13,
          v14,
          v16,
          v17,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "stepGoals",
            "storageKey": null,
            "args": null,
            "concreteType": "StepGoal",
            "plural": true,
            "selections": [
              v12,
              v7,
              v2,
              v11,
              v6,
              v8,
              v10,
              v1,
              v21,
              v13,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actionPlans",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionPlan",
                "plural": true,
                "selections": [
                  v12,
                  v7,
                  v21,
                  v2,
                  v6,
                  v8,
                  v13,
                  v10,
                  v1,
                  v11,
                  v20
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98210fb5217c373bfc28629b6c3982a2';
module.exports = node;
