/**
 * @flow
 * @relayHash 401cf859700d10d06f61f73a7a57ac7c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_AdminProfile_QueryVariables = {||};
export type routes1_AdminProfile_QueryResponse = {|
  +profile: ?{|
    +id: string,
    +name: string,
    +isSuperadmin: ?boolean,
    +activeCompanyId: ?string,
    +activePathId: ?string,
    +email: string,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
    +pic: ?{|
      +id: string,
      +name: string,
      +slug: ?string,
    |},
  |},
  +companies: ?{|
    +id: string,
    +activeCompanyId: ?string,
    +companies: ?$ReadOnlyArray<{|
      +name: string,
      +id: string,
      +state: ?$ReadOnlyArray<{|
        +name: string,
        +attr: string,
        +value: string,
      |}>,
      +strategies: $ReadOnlyArray<{|
        +status: Status,
        +id: string,
        +path: ?string,
        +isCoaching: ?boolean,
        +employees: $ReadOnlyArray<{|
          +id: string
        |}>,
        +stepGoals: $ReadOnlyArray<{|
          +name: string,
          +path: ?string,
          +status: Status,
          +index: number,
          +id: string,
          +actionPlans: $ReadOnlyArray<{|
            +path: ?string,
            +name: string,
            +status: Status,
            +index: number,
            +id: string,
          |}>,
        |}>,
        +name: string,
        +bigGoals: $ReadOnlyArray<{|
          +id: string,
          +index: number,
          +path: ?string,
          +name: string,
          +status: Status,
          +stepGoals: $ReadOnlyArray<{|
            +name: string,
            +path: ?string,
            +status: Status,
            +index: number,
            +id: string,
            +actionPlans: $ReadOnlyArray<{|
              +path: ?string,
              +name: string,
              +status: Status,
              +index: number,
              +id: string,
            |}>,
          |}>,
        |}>,
      |}>,
    |}>,
  |},
|};
*/


/*
query routes1_AdminProfile_Query {
  profile: me {
    id
    name
    isSuperadmin
    email
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
    pic {
      id
      name
      slug
    }
  }
  companies: me {
    id
    companies {
      name
      id
      strategies {
        status
        id
        path
        isCoaching
        employees {
          id
        }
        stepGoals {
          name
          path
          status
          index
          id
          actionPlans {
            path
            name
            status
            index
            id
          }
        }
        name
        bigGoals {
          id
          index
          path
          name
          status
          stepGoals {
            name
            path
            status
            index
            id
            actionPlans {
              path
              name
              status
              index
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperadmin",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeCompanyId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "employment",
  "storageKey": null,
  "args": null,
  "concreteType": "Employment",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeRole",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pic",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "stepGoals",
  "storageKey": null,
  "args": null,
  "concreteType": "StepGoal",
  "plural": true,
  "selections": [
    v1,
    v8,
    v7,
    v9,
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actionPlans",
      "storageKey": null,
      "args": null,
      "concreteType": "ActionPlan",
      "plural": true,
      "selections": [
        v8,
        v1,
        v7,
        v9,
        v0
      ]
    }
  ]
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "strategies",
  "storageKey": null,
  "args": null,
  "concreteType": "Strategy",
  "plural": true,
  "selections": [
    v7,
    v0,
    v8,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCoaching",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "employees",
      "storageKey": null,
      "args": null,
      "concreteType": "Employee",
      "plural": true,
      "selections": [
        v0
      ]
    },
    v10,
    v1,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "bigGoals",
      "storageKey": null,
      "args": null,
      "concreteType": "BigGoal",
      "plural": true,
      "selections": [
        v0,
        v9,
        v8,
        v1,
        v7,
        v10
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_AdminProfile_Query",
  "id": null,
  "text": "query routes1_AdminProfile_Query {\n  profile: me {\n    id\n    name\n    isSuperadmin\n    email\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n    pic {\n      id\n      name\n      slug\n    }\n  }\n  companies: me {\n    id\n    companies {\n      name\n      id\n      strategies {\n        status\n        id\n        path\n        isCoaching\n        employees {\n          id\n        }\n        stepGoals {\n          name\n          path\n          status\n          index\n          id\n          actionPlans {\n            path\n            name\n            status\n            index\n            id\n          }\n        }\n        name\n        bigGoals {\n          id\n          index\n          path\n          name\n          status\n          stepGoals {\n            name\n            path\n            status\n            index\n            id\n            actionPlans {\n              path\n              name\n              status\n              index\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_AdminProfile_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "profile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activePathId",
            "args": null,
            "storageKey": null
          },
          v4,
          v5,
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "companies",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companies",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": true,
            "selections": [
              v1,
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "state",
                "storageKey": null,
                "args": null,
                "concreteType": "State",
                "plural": true,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "attr",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "value",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              v11
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_AdminProfile_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "profile",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v4,
          v5,
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "companies",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companies",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": true,
            "selections": [
              v1,
              v0,
              v11
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e424a2928a68e0b500f53f07887ece7';
module.exports = node;
