/**
 * @flow
 * @relayHash 1abe259fdc42dc789c2df637ffa05908
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_CoachingStrategy_QueryVariables = {|
  companyId: string,
  strategyId: string,
|};
export type routes1_CoachingStrategy_QueryResponse = {|
  +me: ?{|
    +id: string,
    +name: string,
  |},
  +strategy: {|
    +name: string,
    +canEdit: ?boolean,
    +canView: ?boolean,
    +path: ?string,
    +description: string,
    +id: string,
    +plannedStart: ?any,
    +plannedEnd: ?any,
    +status: Status,
    +employees: $ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +user: {|
        +id: string,
        +name: string,
        +email: string,
      |},
    |}>,
    +possibleEmployees: ?$ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +user: {|
        +id: string,
        +name: string,
        +email: string,
      |},
    |}>,
    +messages: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +text: string,
      +from: {|
        +id: string,
        +email: string,
        +name: string,
      |},
    |}>,
    +stepGoals: $ReadOnlyArray<{|
      +canEdit: ?boolean,
      +canView: ?boolean,
      +name: string,
      +index: number,
      +plannedStart: ?any,
      +plannedEnd: ?any,
      +description: string,
      +id: string,
      +responsible: ?{|
        +user: {|
          +name: string,
          +id: string,
        |}
      |},
      +status: Status,
      +actionPlans: $ReadOnlyArray<{|
        +canEdit: ?boolean,
        +canView: ?boolean,
        +responsible: ?{|
          +user: {|
            +name: string,
            +id: string,
          |}
        |},
        +name: string,
        +plannedStart: ?any,
        +plannedEnd: ?any,
        +status: Status,
        +description: string,
        +id: string,
        +index: number,
        +done: number,
      |}>,
    |}>,
  |},
|};
*/


/*
query routes1_CoachingStrategy_Query(
  $companyId: ID!
  $strategyId: ID!
) {
  me {
    id
    name
  }
  strategy(companyId: $companyId, strategyId: $strategyId) {
    name
    canEdit
    canView
    path
    description
    id
    plannedStart
    plannedEnd
    status
    employees {
      id
      role
      user {
        id
        name
        email
      }
    }
    possibleEmployees {
      id
      role
      user {
        id
        name
        email
      }
    }
    messages {
      id
      createdAt
      text
      from {
        id
        email
        name
      }
    }
    stepGoals {
      canEdit
      canView
      name
      index
      plannedStart
      plannedEnd
      description
      id
      responsible {
        user {
          name
          id
        }
        id
      }
      status
      actionPlans {
        canEdit
        canView
        responsible {
          user {
            name
            id
          }
          id
        }
        name
        plannedStart
        plannedEnd
        status
        description
        id
        index
        done
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "strategyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v1,
    v2
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  },
  {
    "kind": "Variable",
    "name": "strategyId",
    "variableName": "strategyId",
    "type": "ID!"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedStart",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canView",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canEdit",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedEnd",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v13 = [
  v1,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "role",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      v1,
      v2,
      v12
    ]
  }
],
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "employees",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": true,
  "selections": v13
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "possibleEmployees",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": true,
  "selections": v13
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "messages",
  "storageKey": null,
  "args": null,
  "concreteType": "Message",
  "plural": true,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "from",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v1,
        v12,
        v2
      ]
    }
  ]
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v2,
    v1
  ]
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v18
  ]
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "done",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "responsible",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": false,
  "selections": [
    v18,
    v1
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_CoachingStrategy_Query",
  "id": null,
  "text": "query routes1_CoachingStrategy_Query(\n  $companyId: ID!\n  $strategyId: ID!\n) {\n  me {\n    id\n    name\n  }\n  strategy(companyId: $companyId, strategyId: $strategyId) {\n    name\n    canEdit\n    canView\n    path\n    description\n    id\n    plannedStart\n    plannedEnd\n    status\n    employees {\n      id\n      role\n      user {\n        id\n        name\n        email\n      }\n    }\n    possibleEmployees {\n      id\n      role\n      user {\n        id\n        name\n        email\n      }\n    }\n    messages {\n      id\n      createdAt\n      text\n      from {\n        id\n        email\n        name\n      }\n    }\n    stepGoals {\n      canEdit\n      canView\n      name\n      index\n      plannedStart\n      plannedEnd\n      description\n      id\n      responsible {\n        user {\n          name\n          id\n        }\n        id\n      }\n      status\n      actionPlans {\n        canEdit\n        canView\n        responsible {\n          user {\n            name\n            id\n          }\n          id\n        }\n        name\n        plannedStart\n        plannedEnd\n        status\n        description\n        id\n        index\n        done\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_CoachingStrategy_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "strategy",
        "storageKey": null,
        "args": v4,
        "concreteType": "Strategy",
        "plural": false,
        "selections": [
          v5,
          v2,
          v6,
          v7,
          v8,
          v1,
          v9,
          v10,
          v11,
          v14,
          v15,
          v16,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "stepGoals",
            "storageKey": null,
            "args": null,
            "concreteType": "StepGoal",
            "plural": true,
            "selections": [
              v10,
              v9,
              v2,
              v17,
              v5,
              v6,
              v8,
              v1,
              v19,
              v11,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actionPlans",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionPlan",
                "plural": true,
                "selections": [
                  v10,
                  v9,
                  v19,
                  v2,
                  v5,
                  v6,
                  v11,
                  v8,
                  v1,
                  v17,
                  v20
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_CoachingStrategy_Query",
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "strategy",
        "storageKey": null,
        "args": v4,
        "concreteType": "Strategy",
        "plural": false,
        "selections": [
          v5,
          v2,
          v6,
          v7,
          v8,
          v1,
          v9,
          v10,
          v11,
          v14,
          v15,
          v16,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "stepGoals",
            "storageKey": null,
            "args": null,
            "concreteType": "StepGoal",
            "plural": true,
            "selections": [
              v10,
              v9,
              v2,
              v17,
              v5,
              v6,
              v8,
              v1,
              v21,
              v11,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actionPlans",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionPlan",
                "plural": true,
                "selections": [
                  v10,
                  v9,
                  v21,
                  v2,
                  v5,
                  v6,
                  v11,
                  v8,
                  v1,
                  v17,
                  v20
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be16890bd82e40f9014fa8ebcb1e412d';
module.exports = node;
