const getPath = (obj, path, defaultValue) => {
  return path.reduce((xs, x) =>
                     (xs && xs[x]) ? xs[x] : null,
                     obj) || defaultValue
}

const extractTokenValues = ({payload, route}) => {
  if (route == 'invite') {
    const auth = payload.getLinkedRecord('auth')
    const token = auth.getValue('token')
    const user = auth.getLinkedRecord('user')
    const name = user.getValue('name')
    return {token, user, name}
  } else {
    const token = payload.getValue('token')
    const user = payload.getLinkedRecord('user')
    const name = user.getValue('name')
    return {token, user, name}
  }
}

const storeToken = ({payload, route, router}) => {  
  const {token, user, name} = extractTokenValues({payload, route})

  console.log(token, user, name)
  let companyId

  if (route == 'login' || route == 'reset') {
    console.log("Checking comipanies?")
    const companies = user.getLinkedRecords('companies')

    console.log("Companyies?", companies)
    if (companies && companies.length > 0) {
      companyId = companies[0].getValue('id')
      window.localStorage.setItem('activeCompanyId', companyId)
    }
  }
  
  if (route == 'invite') {
    companyId = payload.getValue('companyId')
    window.localStorage.setItem('activeCompanyId', companyId)
  }
  
  
  window.localStorage.setItem("userToken", token)
  let users = window.localStorage.getItem('users') || "[]"
  users = JSON.parse(users)
  
  users.push({token, name})
  window.localStorage.setItem("users", JSON.stringify(users))

  if (companyId) {
    router.replace("/company/" + companyId)
    return
  }
  
   if (route == 'login') {
    const superAdmin = user.getValue('isSuperadmin')
    if (superAdmin) {
      router.replace("/admin/companies")
    } else {
      router.replace("/profile")
    }
   } else {
     router.replace("/profile")     
   }
}

const maxStrDisplay = (str,num) => {
  return str.length > num ? str.substring(0, num) + "..." : str
}

const typeFn = (t) => {
  if (t == "Company") {
    return "company"
  }
  else if (t == "Strategy") {
    return "strategy"
  } else if (t == "Big Goal") {
    return "bigGoal"
  } else if (t == "Step Goal") {
    return "stepGoal"
  } else if (t == "Action Plan") {
    return "actionPlan"
  } else if (t == "Employee") {
    return "employee"
  }
}

const recursiveDone = (item, tasks) => {
  let children = item.bigGoals || item.stepGoals || item.actionPlans

  if (item.status != "ACTIVE" && !item.bigGoals) {    
    return;
  } else {
    if (item.done !== null && item.done !== undefined) {
      tasks.push(item.done)
    }
    
    if (children) {
      children.forEach((c) => {
        recursiveDone(c, tasks)
      })
    }
  }
  
}

const calculateDone = (tasks) => {
  if (tasks.length == 0) {
    return 0
  }
  return tasks.reduce((total, t) => {
    return total + t
  }) / (tasks.length * 100)
}

const percentDone = (item) => {  
  let tasks = []  
  recursiveDone(item, tasks)  
  return calculateDone(tasks)
}

const addStateManagement = (component) => {
  component.state.errors = []
  component.stateManagement = {    
    setLoading(t) {
      component.setState({isLoading: t})
    },
    addError(e) {      
      component.state.errors.push(e)
      component.setState({errors: component.state.errors, isLoading: false})
    },
    clearErrors() {
      component.state.errors = []
      component.setState({errors: component.state.errors})
    },
    popError(idx) {
      component.state.errors.splice(idx, 1)
      component.setState({errors: component.state.errors})
    }
  }
}

function createUUID() {
    // http://www.ietf.org/rfc/rfc4122.txt
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}



const createState = ({proxy, id, name, attr, value, sourceId}) =>{
  let stateId = id || createUUID()
  let state = proxy.create(id, "State")
  state.setValue(stateId, 'id')
  state.setValue(name, 'name')
  state.setValue(attr, 'attr')
  state.setValue(value, 'value')
  
  if (sourceId){    
    state.setValue(sourceId, 'sourceId')
  }

  return state
}

const appendState = ({proxy, sourceId, id, name, attr, value}) => {
  console.log(proxy, sourceId, id, name, attr)
  const item = proxy.get(sourceId)
  const states = item.getLinkedRecords("state") || []
  item.setLinkedRecords([...states, createState({proxy, id, sourceId, name, attr, value})], "state")
}

const getStateValue = ({stateId, state}) => {
  return state.filter((s) => {
    return stateId == s.id
  })[0].value
}

const runSaveFns = (saveWrapper, type, data) => {  
  saveWrapper[type].forEach((f) => {    
    f(data)    
  })  
}

const saveWrap = (prevSave, newSave) => {
  let save = prevSave || {onComplete: [], onSuccess: [], onError: []}
  if (!save.runFns) {
    save.run = (type, data) => {
      save[type].forEach((f) => {    
        f(data)    
      })
    }
  }
  
  if (newSave && newSave.onComplete) {
    save.onComplete.push(newSave.onComplete)
  }

  if (newSave && newSave.onSuccess) {
    save.onSuccess.push(newSave.onSuccess)
  }

  if (newSave && newSave.onError) {
    save.onError.push(newSave.onError)
  }
  
  return save
}

const recursivePath = (item, paths, pathObj) => {  
  let children = item.bigGoals || item.stepGoals || item.actionPlans || item.strategies

  let type;
  if (item.strategies) {
    type = "Company"
  } else if (item.bigGoals) {
    type = "Strategy"
  } else if (item.stepGoals) {
    type = "Big Goal"
  } else if (item.actionPlans) {
    type = "Step Goal"
  } else {
    type = "Action Plan"
  }

  if (item.isCoaching) {
    children = item.stepGoals
    type = "Coaching Strategy"
  }

  const newPath = paths.concat([{name: item.name, type, index: item.index, id: item.id}])
  
  pathObj[item.id] = newPath

  if (children) {    
    children.forEach((c) => {
      recursivePath(c, newPath, pathObj)
    })
  }
}

// removeState
// Grab the sourceId
// getLinkedRecords state
// Remove the state that matches the id

// Are the lengths of the arrays the same?
// If not, need to update the setLinkedRecords
// filter != removeId

// Look up the notificationArea (from root)
// Look at the notifications array
// If found, need to update the notifications
// setLinkedRecords using the new array

const displayPercent = (percent) => {
  return `${(100 * percent).toFixed(0)}%`
}

const disableLink = (e) => {                 
                 e.preventDefault()
                 return false
               }

module.exports = {
  displayPercent,
  disableLink,
  getStateValue,
  createUUID,
  createState,
  appendState,
  maxStrDisplay,
  typeFn,
  storeToken,
  maxStrDisplay,
  getPath,
  percentDone,
  addStateManagement,
  saveWrap,
  runSaveFns,
  recursivePath
}
