/**
 * @flow
 * @relayHash 2be15bb63afc086e7bd0e9a144b64171
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type Status = "ACTIVE" | "COMPLETED" | "CREATED" | "DEACTIVE" | "DELETED" | "%future added value";
export type routes1_CompanyHeader_QueryVariables = {|
  companyId: string
|};
export type routes1_CompanyHeader_QueryResponse = {|
  +activeCompany: {|
    +name: string,
    +id: string,
    +state: ?$ReadOnlyArray<{|
      +name: string,
      +attr: string,
      +value: string,
    |}>,
    +strategies: $ReadOnlyArray<{|
      +status: Status,
      +id: string,
      +path: ?string,
      +isCoaching: ?boolean,
      +employees: $ReadOnlyArray<{|
        +id: string
      |}>,
      +stepGoals: $ReadOnlyArray<{|
        +name: string,
        +path: ?string,
        +status: Status,
        +index: number,
        +id: string,
        +actionPlans: $ReadOnlyArray<{|
          +path: ?string,
          +name: string,
          +status: Status,
          +index: number,
          +id: string,
        |}>,
      |}>,
      +name: string,
      +bigGoals: $ReadOnlyArray<{|
        +id: string,
        +index: number,
        +path: ?string,
        +name: string,
        +status: Status,
        +stepGoals: $ReadOnlyArray<{|
          +name: string,
          +path: ?string,
          +status: Status,
          +index: number,
          +id: string,
          +actionPlans: $ReadOnlyArray<{|
            +path: ?string,
            +name: string,
            +status: Status,
            +index: number,
            +id: string,
          |}>,
        |}>,
      |}>,
    |}>,
  |},
  +profile1: ?{|
    +id: string,
    +name: string,
    +isSuperadmin: ?boolean,
    +activeCompanyId: ?string,
    +activePathId: ?string,
    +email: string,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
    +pic: ?{|
      +id: string,
      +name: string,
      +slug: ?string,
    |},
  |},
|};
*/


/*
query routes1_CompanyHeader_Query(
  $companyId: ID!
) {
  activeCompany: company(companyId: $companyId) {
    name
    id
    strategies {
      status
      id
      path
      isCoaching
      employees {
        id
      }
      stepGoals {
        name
        path
        status
        index
        id
        actionPlans {
          path
          name
          status
          index
          id
        }
      }
      name
      bigGoals {
        id
        index
        path
        name
        status
        stepGoals {
          name
          path
          status
          index
          id
          actionPlans {
            path
            name
            status
            index
            id
          }
        }
      }
    }
  }
  profile1: me {
    id
    name
    isSuperadmin
    email
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
    pic {
      id
      name
      slug
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "path",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "index",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "stepGoals",
  "storageKey": null,
  "args": null,
  "concreteType": "StepGoal",
  "plural": true,
  "selections": [
    v2,
    v5,
    v4,
    v6,
    v3,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actionPlans",
      "storageKey": null,
      "args": null,
      "concreteType": "ActionPlan",
      "plural": true,
      "selections": [
        v5,
        v2,
        v4,
        v6,
        v3
      ]
    }
  ]
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "strategies",
  "storageKey": null,
  "args": null,
  "concreteType": "Strategy",
  "plural": true,
  "selections": [
    v4,
    v3,
    v5,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isCoaching",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "employees",
      "storageKey": null,
      "args": null,
      "concreteType": "Employee",
      "plural": true,
      "selections": [
        v3
      ]
    },
    v7,
    v2,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "bigGoals",
      "storageKey": null,
      "args": null,
      "concreteType": "BigGoal",
      "plural": true,
      "selections": [
        v3,
        v6,
        v5,
        v2,
        v4,
        v7
      ]
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isSuperadmin",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "employment",
  "storageKey": null,
  "args": null,
  "concreteType": "Employment",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "companyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "employeeRole",
      "args": null,
      "storageKey": null
    }
  ]
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pic",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    v3,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_CompanyHeader_Query",
  "id": null,
  "text": "query routes1_CompanyHeader_Query(\n  $companyId: ID!\n) {\n  activeCompany: company(companyId: $companyId) {\n    name\n    id\n    strategies {\n      status\n      id\n      path\n      isCoaching\n      employees {\n        id\n      }\n      stepGoals {\n        name\n        path\n        status\n        index\n        id\n        actionPlans {\n          path\n          name\n          status\n          index\n          id\n        }\n      }\n      name\n      bigGoals {\n        id\n        index\n        path\n        name\n        status\n        stepGoals {\n          name\n          path\n          status\n          index\n          id\n          actionPlans {\n            path\n            name\n            status\n            index\n            id\n          }\n        }\n      }\n    }\n  }\n  profile1: me {\n    id\n    name\n    isSuperadmin\n    email\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n    pic {\n      id\n      name\n      slug\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_CompanyHeader_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "activeCompany",
        "name": "company",
        "storageKey": null,
        "args": v1,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "state",
            "storageKey": null,
            "args": null,
            "concreteType": "State",
            "plural": true,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "attr",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "value",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v8
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "profile1",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3,
          v2,
          v9,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activeCompanyId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "activePathId",
            "args": null,
            "storageKey": null
          },
          v10,
          v11,
          v12
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_CompanyHeader_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "activeCompany",
        "name": "company",
        "storageKey": null,
        "args": v1,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v2,
          v3,
          v8
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "profile1",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3,
          v2,
          v9,
          v10,
          v11,
          v12
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8aaa09bbe1cb9dd6a5a18853ae351470';
module.exports = node;
