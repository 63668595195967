/**
 * @flow
 * @relayHash 629a4fd490d56b107596ae1639f57bf5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Wrapper_me1$ref = any;
export type routes1_Wrapper_QueryVariables = {||};
export type routes1_Wrapper_QueryResponse = {|
  +me1: ?{|
    +$fragmentRefs: Wrapper_me1$ref
  |}
|};
*/


/*
query routes1_Wrapper_Query {
  me1 {
    ...Wrapper_me1
    id
  }
}

fragment Wrapper_me1 on User {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_Wrapper_Query",
  "id": null,
  "text": "query routes1_Wrapper_Query {\n  me1 {\n    ...Wrapper_me1\n    id\n  }\n}\n\nfragment Wrapper_me1 on User {\n  id\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_Wrapper_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me1",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Wrapper_me1",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_Wrapper_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me1",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = '7c94cf5048e788bbb6a65e7a57881450';
module.exports = node;
