/**
 * @flow
 * @relayHash 0930ce400ccd7695eea545468adff1a6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompanyArchive_company$ref = any;
export type routes1_CompanyArchive_QueryVariables = {|
  companyId: string
|};
export type routes1_CompanyArchive_QueryResponse = {|
  +company: {|
    +$fragmentRefs: CompanyArchive_company$ref
  |}
|};
*/


/*
query routes1_CompanyArchive_Query(
  $companyId: ID!
) {
  company(companyId: $companyId) {
    ...CompanyArchive_company
    id
  }
}

fragment CompanyArchive_company on Company {
  id
  canView
  canEdit
  name
  strategies {
    id
    path
    canView
    isCoaching
    canEdit
    name
    description
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canView",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canEdit",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_CompanyArchive_Query",
  "id": null,
  "text": "query routes1_CompanyArchive_Query(\n  $companyId: ID!\n) {\n  company(companyId: $companyId) {\n    ...CompanyArchive_company\n    id\n  }\n}\n\nfragment CompanyArchive_company on Company {\n  id\n  canView\n  canEdit\n  name\n  strategies {\n    id\n    path\n    canView\n    isCoaching\n    canEdit\n    name\n    description\n    status\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_CompanyArchive_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": v1,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CompanyArchive_company",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_CompanyArchive_Query",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": v1,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "strategies",
            "storageKey": null,
            "args": null,
            "concreteType": "Strategy",
            "plural": true,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "path",
                "args": null,
                "storageKey": null
              },
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isCoaching",
                "args": null,
                "storageKey": null
              },
              v4,
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eaee85903c93126e3bff793479527136';
module.exports = node;
