/**
 * @flow
 * @relayHash 61930979531d29af3fdbb1b89e2cb5a4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type routes1_Home_QueryVariables = {||};
export type routes1_Home_QueryResponse = {|
  +me: ?{|
    +id: string,
    +isSuperadmin: ?boolean,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
  |}
|};
*/


/*
query routes1_Home_Query {
  me {
    id
    isSuperadmin
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isSuperadmin",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "employment",
        "storageKey": null,
        "args": null,
        "concreteType": "Employment",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "employeeId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "employeeName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "employeeRole",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_Home_Query",
  "id": null,
  "text": "query routes1_Home_Query {\n  me {\n    id\n    isSuperadmin\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_Home_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_Home_Query",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d5ddbc25573c701f978da53a0be7be8';
module.exports = node;
