/**
 * @flow
 * @relayHash d013363ea6598340e3a26d72874e471e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployeeRole = "ADMIN" | "ASSIGNED" | "MANAGER" | "SUPERADMIN" | "USER" | "%future added value";
export type routes1_CreateStrategy_QueryVariables = {|
  companyId: string
|};
export type routes1_CreateStrategy_QueryResponse = {|
  +me: ?{|
    +id: string,
    +employment: ?$ReadOnlyArray<{|
      +companyId: string,
      +companyName: string,
      +employeeId: string,
      +employeeName: string,
      +employeeRole: EmployeeRole,
    |}>,
  |},
  +company: {|
    +employees: $ReadOnlyArray<{|
      +id: string,
      +role: EmployeeRole,
      +user: {|
        +id: string,
        +name: string,
      |},
    |}>
  |},
|};
*/


/*
query routes1_CreateStrategy_Query(
  $companyId: ID!
) {
  me {
    id
    employment {
      companyId
      companyName
      employeeId
      employeeName
      employeeRole
    }
  }
  company(companyId: $companyId) {
    employees {
      id
      role
      user {
        id
        name
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v1,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "employment",
      "storageKey": null,
      "args": null,
      "concreteType": "Employment",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "companyId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "companyName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "employeeId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "employeeName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "employeeRole",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v3 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId",
    "type": "ID!"
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "employees",
  "storageKey": null,
  "args": null,
  "concreteType": "Employee",
  "plural": true,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "role",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "routes1_CreateStrategy_Query",
  "id": null,
  "text": "query routes1_CreateStrategy_Query(\n  $companyId: ID!\n) {\n  me {\n    id\n    employment {\n      companyId\n      companyName\n      employeeId\n      employeeName\n      employeeRole\n    }\n  }\n  company(companyId: $companyId) {\n    employees {\n      id\n      role\n      user {\n        id\n        name\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "routes1_CreateStrategy_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": v3,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routes1_CreateStrategy_Query",
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": v3,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v4,
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b933eb082fa76d3adfb3d186d66f333';
module.exports = node;
